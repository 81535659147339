<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <Navbar title="证书培训" type="SMP" :callback="back" />
    <van-tabs v-model="active" :color="COLOR_M">
      <van-tab title="培训列表"
        ><van-list
          :finished="finished"
          finished-text="没有更多了"
          offset="0"
          class="task-list"
          :immediate-check="false"
          @load="retrieveTaskList"
        >
          <template #default>
            <van-row>
              <van-col v-for="task in taskList" :key="task.code" span="24">
                <div class="task" @click="detail(task.code)">
                  <van-row>
                    <van-col span="8"
                      ><div class="lable">
                        <van-tag :color="COLOR_S1">热门</van-tag>
                      </div>
                      <div class="image">
                        <van-image :src="task.imagePath"></van-image></div
                    ></van-col>
                    <van-col span="16"
                      ><div class="name">{{ task.name }}</div>
                      <van-row>
                        <van-col span="10" class="type"
                          ><van-tag :color="COLOR_M" v-if="task.type === 'ONE'"
                            >线上培训</van-tag
                          ></van-col
                        >
                        <van-col span="14" class="price color-s2"
                          ><van-icon name="cash-back-record" />
                          <span v-if="task.priceMin != 0">{{
                            task.priceMin
                          }}</span
                          ><span v-if="task.priceMin != 0 && task.priceMax != 0"
                            >~</span
                          >
                          <span v-if="task.priceMax != 0">{{
                            task.priceMax
                          }}</span
                          >元</van-col
                        >
                      </van-row>
                      <van-row>
                        <van-col span="18">
                          <div class="score">
                            课程评分：<van-rate
                              v-model="task.score"
                              readonly
                              size="14"
                              :color="COLOR_S1"
                            />
                          </div>
                        </van-col>
                        <van-col span="6" class="count">剩余5人</van-col>
                      </van-row>
                    </van-col>
                  </van-row>
                </div>
              </van-col>
            </van-row>
            <van-row v-if="taskList.length <= 0">
              <van-col span="24" class="no-record">
                <van-image
                  width="103"
                  height="103"
                  :src="require('../../../../assets/images/home/no-record.png')"
                ></van-image>
              </van-col>
            </van-row>
          </template> </van-list
      ></van-tab>
      <van-tab title="特惠拼团"
        ><van-image
          width="103"
          height="103"
          :src="require('../../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-tab>
    </van-tabs>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import { Image, Tag, Popup, List, Icon, Rate, Tabs, Tab } from 'vant'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import Navbar from '../../common/Navbar.vue'
import Login from '../../intercept/Login.vue'
export default {
  components: {
    Loading: Loading,
    Share: Share,
    Navbar: Navbar,
    Login: Login,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [Popup.name]: Popup,
    [List.name]: List,
    [Icon.name]: Icon,
    [Rate.name]: Rate,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab
  },
  data () {
    return {
      finished: false,
      loadingShow: false,
      certCode: '',
      active: '',
      page: { current: 0, size: 10, last: 0 },
      taskList: []
    }
  },
  mounted () {
    document.title = '证书培训'
    var query = this.$route.query
    this.certCode = query.certCode
    this.$refs.login.validate()
  },
  methods: {
    init () {
      this.retrieveTaskList()
      this.$refs.share.default('main')
    },
    search () {
      this.$router.push({ path: '/mde/search/home', query: { keyword: this.keyword, type: 'TRA' } })
    },
    classifyChose () {
      this.taskList = []
      this.page.current = 0
      this.finished = false
      this.retrieveTaskList()
    },
    detail (code) {
      window.sessionStorage.setItem(this.SESSION_BACKURL, window.location.href)
      this.$router.push({ path: '/mde/train/detail', query: { taskCode: code } })
    },
    async retrieveTaskList () {
      if (!this.finished) {
        this.loadingShow = true
        this.page.current = this.page.current + 1
        var pd = { certCode: this.certCode, current: this.page.current, size: this.page.size }
        const { data: res } = await this.$http.post(this.BMS_URL + '/train/taskAPC/retrieveCertTaskList', this.$qs.stringify(pd))
        if (res.status === '200') {
          this.taskList = this.taskList.concat(res.data)
          this.page.last = res.page.last
          if (res.data.length <= 0) {
            this.finished = true
          }
        }
        this.loadingShow = false
      }
    },
    back () {
      var key = window.const.global.TRAIN_CERT_CERTTASK_BACK
      var url = window.sessionStorage.getItem(key)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.keywords {
  .back-btn {
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    color: #fff;
  }
  .text {
    color: #fff;
  }
}
.dropdown {
  ::v-deep .van-dropdown-menu__bar {
    height: 35px;
  }
}
.task-list {
  padding-bottom: 60px;
  width: 100%;
  position: absolute;
  .task {
    text-align: left;
    margin: 5px;
    border: 1px solid #eee;
    position: relative;
    .image {
      margin-top: 2px;
    }
    .lable {
      float: left;
      z-index: 1;
      left: 0;
      position: absolute;
    }
    .name {
      padding-left: 5px;
      padding-top: 5px;
      font-weight: 600;
      height: 20px;
      line-height: 20px;
      text-align: left;
      font-size: 14px;
    }
    .type {
      padding-left: 5px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #bbb;
    }
    .price {
      font-size: 12px;
      font-weight: 600;
      height: 20px;
      line-height: 20px;
      text-align: right;
      padding-right: 10px;
    }
    .score {
      padding-left: 5px;
      height: 20px;
      line-height: 20px;
      color: #aaa;
      text-align: left;
    }
    .count {
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #aaa;
    }
    ::v-deep .van-tag {
      font-size: 10px;
    }
  }
}
.no-record {
  margin-top: 80px;
}
</style>
